<template>
  <v-card>
    <v-row no-gutters>
      <v-col v-if="hasOtherComponent" cols="6"
        ><exercise-video
          v-if="showVideo"
          title="Test Blazepose Video"
          id="TestId"
          :link="videoLink"
          :disclaimer="$t('wellness.v2.disclaimer')"
          :disclaimerSmallScreen="$t('wellness.v2.disclaimerSmallScreen')"
          :showDisclaimer="false"
          :videoMetaData="videoMetaData"
        />
      </v-col>
      <v-col :cols="hasOtherComponent ? '6' : '12'"
        ><blaze-pose-camera />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import BlazePoseCamera from "@/components/common/webcam-tracking/BlazePoseCamera.vue";
import ExerciseVideo from "@/components/common/exercise-videos/ExerciseVideo.vue";
import { mapGetters } from "vuex";

export default {
  name: "BlazePoseTestPage",
  components: {
    BlazePoseCamera,
    ExerciseVideo
  },
  data() {
    return {
      showVideo: true,
      videoMetaData: null,
      videoLink: "https://www.youtube.com/watch?v=CHoBTuGpp-w"
    };
  },
  computed: {
    ...mapGetters(["vitrueDeveloper"]),
    hasOtherComponent() {
      return this.showVideo;
    }
  },
  async mounted() {
    if (!this.vitrueDeveloper) {
      this.$router.push("/dashboard", () => {});
    }
  }
};
</script>
