import {
  POSE_CONNECTIONS,
  POSE_LANDMARKS_LEFT,
  POSE_LANDMARKS_RIGHT,
  POSE_LANDMARKS_NEUTRAL
} from "@mediapipe/pose";
import drawingUtils from "@mediapipe/drawing_utils";

export default {
  updateCanvas(results, canvasContext, minimumConfidence) {
    canvasContext.save();
    canvasContext.clearRect(0, 0, results.image.width, results.image.height);
    canvasContext.drawImage(
      results.image,
      0,
      0,
      results.image.width,
      results.image.height
    );
    this.drawPose(results, canvasContext, minimumConfidence);
    canvasContext.restore();
  },
  drawPose(results, canvasContext, minConfidence) {
    if (results.poseLandmarks) {
      drawingUtils.drawConnectors(
        canvasContext,
        results.poseLandmarks,
        POSE_CONNECTIONS,
        { visibilityMin: minConfidence, color: "white" }
      );
      drawingUtils.drawLandmarks(
        canvasContext,
        Object.values(POSE_LANDMARKS_LEFT).map(
          index => results.poseLandmarks[index]
        ),
        {
          visibilityMin: minConfidence,
          color: "white",
          fillColor: "rgb(255,138,0)"
        }
      );
      drawingUtils.drawLandmarks(
        canvasContext,
        Object.values(POSE_LANDMARKS_RIGHT).map(
          index => results.poseLandmarks[index]
        ),
        {
          visibilityMin: minConfidence,
          color: "white",
          fillColor: "rgb(0,217,231)"
        }
      );
      drawingUtils.drawLandmarks(
        canvasContext,
        Object.values(POSE_LANDMARKS_NEUTRAL).map(
          index => results.poseLandmarks[index]
        ),
        {
          visibilityMin: minConfidence,
          color: "white",
          fillColor: "white"
        }
      );
    }
  }
};
