<template>
  <v-row justify="center" align="center" class="canvas-sizing">
    <div class="output-container">
      <blaze-pose-overlay
        v-if="!cameraLoading"
        title="Squat Exercise"
        :counterValue="1"
        sideBar
      />
      <video
        class="input_video"
        ref="input_video"
        style="display: none;"
      ></video>
      <canvas
        v-show="!cameraLoading"
        class="canvas-sizing"
        ref="output_canvas"
        :width="width"
        :height="height"
      >
      </canvas>

      <v-row
        v-show="cameraLoading"
        class="text-h4 canvas-sizing"
        justify="center"
        align="center"
      >
        {{ $t("trackedWebCamComponent.loading.smallScreen") }}
      </v-row>
    </div>
  </v-row>
</template>

<script>
import { Camera } from "@mediapipe/camera_utils";
import { Pose } from "@mediapipe/pose";
import BlazePoseDrawing from "@/services/tracking/blazepose-drawing.js";
import BlazePoseSkeleton from "@/services/tracking/blazepose-skeleton";
import BlazePoseOverlay from "./BlazePoseOverlay.vue";

export default {
  name: "BlazePoseCamera",
  components: {
    BlazePoseOverlay
  },
  data() {
    return {
      cameraLoading: true,
      number: null,
      ctx: null,
      width: null,
      height: null,
      minimumConfidence: 0.5
    };
  },
  mounted() {
    this.ctx = this.$refs.output_canvas.getContext("2d");
    this.init();
  },
  computed: {
    inputVideo() {
      return this.$refs.input_video;
    }
  },
  methods: {
    init() {
      const pose = new Pose({
        locateFile: file => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
        }
      });

      pose.setOptions({
        modelComplexity: 1,
        smoothLandmarks: true,
        smoothSegmentation: true,
        minDetectionConfidence: this.minimumConfidence,
        minTrackingConfidence: this.minimumConfidence
      });
      pose.onResults(this.onResults);

      const camera = new Camera(this.inputVideo, {
        onFrame: async () => {
          await pose.send({ image: this.inputVideo });
        }
      });
      camera.start();
    },
    onResults(results) {
      if (this.cameraLoading) {
        this.cameraLoading = false;
      }

      this.width = results.image.width;
      this.height = results.image.height;
      BlazePoseDrawing.updateCanvas(results, this.ctx, this.minimumConfidence);
      this.collectSkeletonData(results);
    },
    collectSkeletonData(results) {
      if (results.poseWorldLandmarks) {
        let skeletonInLocalPoints = BlazePoseSkeleton.convertArrayToJointPoints(
          results.poseLandmarks
        );
        let skeletonInWorldPoints = BlazePoseSkeleton.convertArrayToJointPoints(
          results.poseWorldLandmarks
        );
      }
    }
  }
};
</script>

<style scoped>
.canvas-sizing {
  width: 100%;
  height: 100%;
}
.output-container {
  position: relative;
}
</style>
