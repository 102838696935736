<template>
  <div class="overlay" :class="overlayType">
    <v-row align-content="space-between" style="height: 100%"
      ><v-col cols="12" align-self="start"
        ><p class="text-center white--text text-h4">
          {{ title }}
        </p></v-col
      >
      <v-col :cols="fullScreen ? '3' : '12'" align-self="center"
        ><v-img
          :src="require('@/assets/images/positioningPerson.png')"
          contain
          max-height="300px"
        />
      </v-col>
      <v-col cols="12" align-self="end"
        ><p class="pl-2 white--text text-h5">
          {{ "Counter: " + counterValue }}
        </p></v-col
      >
    </v-row>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    title: String,
    counterValue: Number,
    fullScreen: Boolean,
    sideBar: Boolean
  },
  computed: {
    overlayType() {
      if (this.fullScreen) {
        return "overlay-full";
      }

      return this.sideBar ? "overlay-sidebar" : "overlay-full";
    }
  }
};
</script>

<style>
.overlay {
  position: absolute;
  height: 98.5%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.overlay-full {
  width: 100%;
}
.overlay-sidebar {
  width: 25%;
}
</style>
