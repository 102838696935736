var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overlay", class: _vm.overlayType },
    [
      _c(
        "v-row",
        {
          staticStyle: { height: "100%" },
          attrs: { "align-content": "space-between" }
        },
        [
          _c("v-col", { attrs: { cols: "12", "align-self": "start" } }, [
            _c("p", { staticClass: "text-center white--text text-h4" }, [
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ])
          ]),
          _c(
            "v-col",
            {
              attrs: {
                cols: _vm.fullScreen ? "3" : "12",
                "align-self": "center"
              }
            },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/images/positioningPerson.png"),
                  contain: "",
                  "max-height": "300px"
                }
              })
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", "align-self": "end" } }, [
            _c("p", { staticClass: "pl-2 white--text text-h5" }, [
              _vm._v(" " + _vm._s("Counter: " + _vm.counterValue) + " ")
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }