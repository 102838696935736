var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _vm.hasOtherComponent
            ? _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _vm.showVideo
                    ? _c("exercise-video", {
                        attrs: {
                          title: "Test Blazepose Video",
                          id: "TestId",
                          link: _vm.videoLink,
                          disclaimer: _vm.$t("wellness.v2.disclaimer"),
                          disclaimerSmallScreen: _vm.$t(
                            "wellness.v2.disclaimerSmallScreen"
                          ),
                          showDisclaimer: false,
                          videoMetaData: _vm.videoMetaData
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: _vm.hasOtherComponent ? "6" : "12" } },
            [_c("blaze-pose-camera")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }