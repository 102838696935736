export default {
  // https://google.github.io/mediapipe/solutions/pose.html for pose documentation
  convertArrayToJointPoints(arrayOfLocations) {
    return {
      leftShoulder: arrayOfLocations[11],
      rightShoulder: arrayOfLocations[12],
      leftElbow: arrayOfLocations[13],
      rightElbow: arrayOfLocations[14],
      leftWrist: arrayOfLocations[15],
      rightWrist: arrayOfLocations[16],
      leftHip: arrayOfLocations[23],
      rightHip: arrayOfLocations[24],
      leftKnee: arrayOfLocations[25],
      rightKnee: arrayOfLocations[26],
      leftAnkle: arrayOfLocations[27],
      rightAnkle: arrayOfLocations[28]
    };
  }
};
