var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "canvas-sizing",
      attrs: { justify: "center", align: "center" }
    },
    [
      _c(
        "div",
        { staticClass: "output-container" },
        [
          !_vm.cameraLoading
            ? _c("blaze-pose-overlay", {
                attrs: { title: "Squat Exercise", counterValue: 1, sideBar: "" }
              })
            : _vm._e(),
          _c("video", {
            ref: "input_video",
            staticClass: "input_video",
            staticStyle: { display: "none" }
          }),
          _c("canvas", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.cameraLoading,
                expression: "!cameraLoading"
              }
            ],
            ref: "output_canvas",
            staticClass: "canvas-sizing",
            attrs: { width: _vm.width, height: _vm.height }
          }),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cameraLoading,
                  expression: "cameraLoading"
                }
              ],
              staticClass: "text-h4 canvas-sizing",
              attrs: { justify: "center", align: "center" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("trackedWebCamComponent.loading.smallScreen")) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }